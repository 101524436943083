<template>
  <div class="ciam-container step-wrapper mt-6">
      <div class="step">
        <div>
          <div class="step-title mb-4"><b>Step 1</b></div>
          <ciam-card :class="getClassForStep(steps[0].number) ">
            <ciam-card-header class=" mt-4">
              <template v-slot:header class="step-logo-container">
                <svg class="step-logo logo1" xmlns="http://www.w3.org/2000/svg" :fill="steps[0].logoColor" viewBox="0 0 512 512"><path :d="steps[0].logoPath"/></svg>
              </template>
              <template v-slot:subtitle><b><h1 class="step-subtitle mt-4">{{ steps[0].title }}</h1></b></template>
            </ciam-card-header>
            <div class="step-text pl-3 pr-3" v-html="stepContent(0)"></div>

          </ciam-card>
        </div>
      </div>
      <div class="separation" ></div>
      <div class="step">
        <div>
          <div class="step-title mb-4"><b>Step 2</b></div>
          <ciam-card :class="getClassForStep(steps[1].number) ">
            <ciam-card-header class=" mt-4">
              <template v-slot:header class="step-logo-container">
                <svg class="step-logo logo1" xmlns="http://www.w3.org/2000/svg" :fill="steps[1].logoColor" viewBox="0 0 512 512"><path :d="steps[1].logoPath"/></svg>
              </template>
              <template v-slot:subtitle><b><h1 class="step-subtitle mt-4">{{ steps[1].title }}</h1></b></template>
            </ciam-card-header>
            <div class="step-text pl-3 pr-3" v-html="stepContent(1)"></div>


          </ciam-card>
        </div>
      </div>
      <div class="separation" ></div>



      <div class="step">
        <div>
          <div class="step-title mb-4"><b>Step 3</b></div>
          <ciam-card :class="getClassForStep(steps[2].number) ">
            <ciam-card-header class=" mt-4">
              <template v-slot:header class="step-logo-container">
                <svg class="step-logo logo1" xmlns="http://www.w3.org/2000/svg" :fill="steps[2].logoColor" viewBox="0 0 512 512"><path :d="steps[2].logoPath"/></svg>
              </template>
              <template v-slot:subtitle><b><h1 class="step-subtitle mt-4">{{ steps[2].title }}</h1></b></template>
            </ciam-card-header>
            <div class="step-text pl-3 pr-3" v-html="stepContent(2)"></div>


          </ciam-card>
        </div>
      </div>

  </div>
</template>
<script>
export default {
  props: ['steps'],
  methods: {
    getClassForStep(stepNumber) {
      var additionalClass = ''
      switch (stepNumber) {
        case 1:
          additionalClass += "step-border-green"
          break;
        case 2:
          additionalClass += "step-shadow-xl"
          break
        case 3:
          additionalClass += "step-border-grey"
          break
        default:
          break;
      }
      return `step ${additionalClass}`
    },
    stepContent(x) {
      return this.steps[x].content;
    }
  }
}
</script>
<style>
.ciam-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
    align-items: center;
}
.step-wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  align-items: center;
}
.step {
  flex: 1;
  text-align: center;
  box-sizing: border-box;
  min-height: 350px;
  height: 22vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 

}
.step-title {
  margin: 0 2%;
  font-size: 150% !important;
  color: black;
  text-shadow: 1px 1px 2px grey;

}
.step-subtitle {
  color: black !important;
  font-size: 120% !important;

}
.step-text {
  height: 50%;
}
.step-logo.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.step-logo {
  height: 15%;
  width: 15%;
  display: inline;
}
.separation {
  width: 80px; 
  height: 2px;
  border-top: 2px dashed #ccc;
  margin: 0 10px;
  margin-top: 10%;
}

.right-final-step {
  width: 80px; 
  height: 2px;
  margin: 0 10px;
}

.step-border-green {
  border-color: green !important;
  border-width: 1px !important;

}

.step-border-grey {
  border-color: rgb(206, 190, 190) !important;
  border-width: 1px !important;
}
.step-shadow-xl {
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.3) !important;
}

.ciam-card-header {
  border-style: none !important;
}
</style>