<template>
  <ciam-page>
    <ciam-three-steps-process :steps="fillSteps()" />
    <ciam-help-creation-success class="margin-top-help" />
  </ciam-page>
</template>
<script>
import CiamHelpCreationSuccess from '@/components/CiamHelpCreationSuccess';
import CiamThreeStepsProcess from '@/components/CiamThreeStepsProcess';
export default {
  name: 'deployment-creation-success',
  components: { CiamHelpCreationSuccess, CiamThreeStepsProcess },
  data() {
    return {
      steps: [
        {
          number: 1,
          logoPath: "M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z",
          logoColor: "green",
          title: "Subscription validated",
          content: `Cloud-IAM received your subscription for your <b>${this.$route.query.planType}</b>, with <b>${this.$route.query.supportLevel}</b> support level, named <b>${this.$route.query.name}</b>`
        },
        {
          number: 2,
          logoPath: "M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z",
          logoColor: "black",
          title: "Creation of Keycloak",
          content: `Cloud-IAM are actively working to create your Keycloak cluster, it may take up to 30 minutes until the server is fully assembled and configured on <b>${this.$route.query.provider}</b>, <b>${this.$route.query.region}</b>.`
        },
        {
          number: 3,
          logoPath: "M16.1 260.2c-22.6 12.9-20.5 47.3 3.6 57.3L160 376l0 103.3c0 18.1 14.6 32.7 32.7 32.7c9.7 0 18.9-4.3 25.1-11.8l62-74.3 123.9 51.6c18.9 7.9 40.8-4.5 43.9-24.7l64-416c1.9-12.1-3.4-24.3-13.5-31.2s-23.3-7.5-34-1.4l-448 256zm52.1 25.5L409.7 90.6 190.1 336l1.2 1L68.2 285.7zM403.3 425.4L236.7 355.9 450.8 116.6 403.3 425.4z",
          logoColor: "black",
          title: "Keycloak’s credentials incoming",
          content: "Once your deployment is ready to use, you will  receive an email with your <b>Keycloak credentials</b>. If you don’t receive the email, please double check in your spam folder."
        },
      ],
    };
  },
  async mounted() { },
  methods: {
    fillSteps() {
      if (this.$route.query.planType == undefined || this.$route.query.supportLevel == undefined || this.$route.query.name == undefined) {
        this.steps[0].content = "Cloud-IAM received your subscription for your deployment"
      }
      if (!(this.$route.query.provider != undefined && this.$route.query.region != undefined)) {
        this.steps[1].content = "Cloud-IAM are actively working to create your Keycloak cluster, it may take up to 30 minutes until the server is fully assembled"
      }
      if (this.$route.query.planType == "Little Lemur" && this.$route.query.name != undefined) {
        this.steps[0].content = `Cloud-IAM received your registration for your free plan <b>Little Lemur</b>, named <b>${this.$route.query.name}</b>`
        this.steps[0].title = "Registration validated"
        this.steps[1].content = `Cloud-IAM are actively working to create your Keycloak, it may take up to 10 minutes until the server is fully assembled and configured on <b>Scaleway (EU).</b>`
      }
      return this.steps
    }
  },
  computed: {},
};
</script>

<style lang="scss">
.margin-top-help {
  margin-top: 15%;
}

.spaced {
  margin-bottom: 2rem;
}
</style>
